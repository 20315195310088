$(document).ready(function() {
    $('.header__menu__btn').on('click',  function () {
        $('body').toggleClass('header__menu__open')
    })
    $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
    $('.menu-item-touch').on('click', function () {
        $(this).parent('.menu-item').toggleClass('open')
    })

    if ($(window).width() > 767) {
        $(window).scroll(function () {
            let scroll = $(window).scrollTop();
            if (scroll > 120) {
                $('.header').addClass('header--fix');
            } else {
                $('.header').removeClass('header--fix');
            }
        });
    }
});
