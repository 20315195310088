export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.header__search__toggle').on('click', function () {
            $('.header__search').toggleClass('open')
            $('.header__search__input input').focus()
        })

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
